<template>
  <v-app id="app_frontend" class="p-b-50 p-t-90">
    <router-view />
  </v-app>
</template>

<script setup>
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const i18n = useI18n();

const props = defineProps(['arabicTranslation', 'isLocal']);

const setLang = () => {
  i18n.messages.ar = props.arabicTranslation || {};
};

onMounted(() => {
  setLang();
  i18n.locale = 'ar';
});
</script>

<style>
.p-b-50 {
  padding-bottom: 50px;
}
.p-t-90 {
  padding-top: 90px;
}
.v-theme--light {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --v-hover-opacity: 0.04;
  --v-theme-overlay-multiplier: 1;
}
</style>
