import { AxiosError, AxiosResponse } from 'axios';
import { reactive, toRefs, type App } from 'vue';

const codeMessagesMap = {
  200: 'Applied successfully',
  201: 'Created successfully',
};

const Fetching = {
  install(app: App) {
    const state = reactive({
      processing: false,
      is_locked: false,
      is_locked_message: '',
    });
    // show snackbar after success/failed requests
    const processReq = function (
      promise: Promise<AxiosResponse>,
      notifyOnOk = true,
      notifyOnError = true,
    ) {
      state.processing = true;
      return promise
        .then((res) => {
          if (notifyOnOk) {
            let message = codeMessagesMap[200];
            if (res.data.message) {
              message = res.data.message;
            } else if (res.status === 200 || res.status === 201) {
              message = codeMessagesMap[res.status];
            }

            app.config.globalProperties.$emitter.emit('snackbar', {
              text: message,
              color: 'success',
            });
          }
          return res;
        })
        .catch(processReqError)
        .catch((err) => {
          if (err.response && err.response.code === 423) {
            state.is_locked = true;
            state.is_locked_message =
              err.response.data && err.response.data.message;
          }
          if (notifyOnError) {
            let text = err.message;
            if (
              err.response &&
              (err.response.data.message || err.response.data.detail)
            ) {
              text = err.response.data.message || err.response.data.detail;
            } else {
              if (err.response && err.response.data) {
                for (const prop in err.response.data) {
                  if (
                    err.response.data &&
                    err.response.data[prop] &&
                    Array.isArray(err.response.data[prop]) &&
                    err.response.data[prop].length
                  ) {
                    err.response.data[prop].forEach((obj: any) => {
                      if (obj.message) {
                        text = obj.message[0];
                      }
                    });
                  }
                }
              }
            }
            if (!text) {
              text = 'Something went wrong. Please try again.';
            }

            app.config.globalProperties.$emitter.emit('snackbar', {
              text,
              color: 'error',
            });
          }
          throw err;
        })
        .finally(() => {
          state.processing = false;
        });
    };
    const processReqSilent = function (promise: Promise<AxiosResponse>) {
      state.processing = true;
      return promise.catch(processReqError).finally((...args) => {
        state.processing = false;
        return args;
      });
    };
    const processReqError = function (err: AxiosError) {
      if (err.response?.status === 401) {
        // means auth token is invalid
        app.config.globalProperties.$logout();
      }
      throw err;
    };

    app.config.globalProperties.$processReq = processReq;
    app.config.globalProperties.$processReqSilent = processReqSilent;

    app.provide('fetchingState', toRefs(state));
    app.provide('$processReq', processReq);
    app.provide('$processReqSilent', processReqSilent);
  },
};

export default Fetching;
