import HomeView from '@/views/HomeView.vue';
import { i18n } from '@/plugins/i18n';
import { RouteRecordRaw } from 'vue-router';

const dashboardRoutes: RouteRecordRaw = {
  path: 'payout',
  component: HomeView,
  children: [
    {
      path: 'overview',
      name: 'overview',
      component: () =>
        import(
          /*webpackChunkName: "Overview"*/ '@/views/OverviewStatistics.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Overview'),
        breadcrumbItems: ['overview'],
      },
    },
    {
      path: 'payouts',
      name: 'payouts',
      component: () =>
        import(/*webpackChunkName: "Payouts"*/ '@/views/PayoutsListing.vue'),
      meta: {
        pageTitle: i18n.global.t('Payouts'),
        breadcrumbItems: ['payouts'],
      },
    },
    {
      path: 'my-tasks',
      name: 'my-tasks',
      component: () =>
        import(/*webpackChunkName: "MyTasks"*/ '@/views/MyTasks.vue'),
      meta: {
        pageTitle: i18n.global.t('My Tasks'),
        breadcrumbItems: ['my-tasks'],
      },
    },
    {
      path: 'beneficiary-listing',
      name: 'beneficiary-listing',
      component: () =>
        import(
          /*webpackChunkName: "BeneficiaryListing"*/ '@/views/beneficiary/BeneficiaryListing.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Beneficiaries'),
        breadcrumbItems: ['beneficiary-listing'],
      },
    },
    {
      path: 'beneficiary-listing/create-beneficiary',
      name: 'create-beneficiary',
      component: () =>
        import(
          /*webpackChunkName: "EditBeneficiary"*/ '@/views/beneficiary/EditBeneficiary.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Beneficiary and Account'),
        breadcrumbItems: ['beneficiary-listing', 'create-beneficiary'],
      },
    },
    {
      path: 'beneficiary-listing/beneficiary/:id',
      name: 'edit-beneficiary',
      component: () =>
        import(
          /*webpackChunkName: "EditBeneficiary"*/ '@/views/beneficiary/EditBeneficiary.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Beneficiary and Account'),
        breadcrumbItems: ['beneficiary-listing', 'edit-beneficiary'],
      },
    },
    {
      path: 'reports',
      name: 'reports',
      component: () =>
        import(/*webpackChunkName: "Reports"*/ '@/views/ReportsListing.vue'),
      meta: {
        pageTitle: i18n.global.t('Reports'),
        breadcrumbItems: ['reports'],
      },
    },
    {
      path: 'sources-listing',
      name: 'sources-listing',
      component: () =>
        import(
          /*webpackChunkName: "SourcesListing"*/ '@/views/source/SourcesListing.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Sources Listing'),
        breadcrumbItems: ['advanced-setup', 'sources-listing'],
      },
    },
    {
      path: 'merchants-account-listing',
      name: 'merchants-account-listing',
      component: () =>
        import(
          /*webpackChunkName: "MerchantSetup"*/ '@/views/merchant/MerchantConfig.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Merchant Setup'),
        breadcrumbItems: ['advanced-setup', 'merchants-account-listing'],
      },
    },
    {
      path: 'policies-configuration',
      name: 'policies-configuration',
      component: () =>
        import(
          /*webpackChunkName: "PoliciesConfiguration"*/ '@/views/policy/PoliciesConfiguration.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Policies Configuration'),
        breadcrumbItems: ['advanced-setup', 'policies-configuration'],
      },
    },
  ],
};

export default dashboardRoutes;
