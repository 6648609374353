// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#app,#app_frontend{font-family:Inter,Poppins,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;font-weight:400}.v-breadcrumbs{padding:16px 0!important}.v-breadcrumbs a,.v-breadcrumbs span{font-size:.875rem;font-weight:500}.v-breadcrumbs a{text-decoration:none;color:inherit}.v-card-title{font-size:1.125rem!important;font-weight:700!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
